<template>
  <div>
    <!-- <div class="foot">
      <div class="foot-cont">
        <img src="../assets/images/link.png" alt="" />
        <div class="news-not">友情链接</div>
      </div>
      <div style="display: inline-block">
        <div
          v-for="(link_item, link_index) in link"
          :key="link_index"
          class="link"
        >
          <a :href="link_item.url" :target="link_item.target">
            <div class="link">
              <span class="link-title">{{ link_item.name }}</span>
            </div>
          </a>
        </div>
      </div>
    </div> -->
    <div class="footer">
      <div class="footer-cont">
        <div class="footer-left">
          <div>
            <p>
              {{ config.site_name }} | {{ config.site_gwa }}
              Rights Reserved
            </p>
            <p style="margin-left: 21px">备案编号 {{ config.site_icp }}</p>
          </div>
          <div>
            <p>联系地址：{{ config.site_admin_addr }}</p>
            <p style="margin-left: 21px">电话：02885146992</p>
            <p style="margin-left: 21px">邮箱：{{ config.site_admin_email }}</p>
          </div>
          <!-- <div>
            <p>网站技术服务：四川川信通信技术工程有限公司</p>
          </div> -->
        </div>
        <div class="footer-right">
          <div class="code">—— 扫码关注官方微信号 ——</div>
          <div class="code-div">
            <div
              v-for="(code_item, code_index) in code"
              :key="code_index"
              class="code-img"
            >
              <div><img :src="code_item.image" alt="" /></div>
              <div class="title">{{ code_item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLinkSite, getHome } from "@/http/api/home/index";
export default {
  props: {},
  data() {
    return {
      link: [], //友情链接
      config: [], //底部配置
      code: [], //二维码配置
    };
  },

  watch: {},
  created() {
    this.getLinkSite(); //获取配置
    this.getHome();
  },
  methods: {
    //获取配置
    getLinkSite() {
      getLinkSite().then((res) => {
        this.link = res.data.data.link;
        this.config = res.data.data.site_info;
      });
    },
    getHome() {
      getHome().then((res) => {
        this.code = res.data.data.gongzhonghao;
        console.log(this.code);
      });
    },
  },
};
</script>

<style lang="less" scope>
.foot {
  width: 1200px;
  margin: 0 auto;
  .foot-cont {
    padding: 20px;
    position: relative;
    .news-not {
      font-size: 16px;
      font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
      font-weight: 700;
      color: #ffffff;
      position: absolute;
      top: 25px;
      left: 38px;
    }
  }
  .link {
    width: 275px;
    height: 36px;
    display: inline-block;
    background: #f7f7f7;
    margin-right: 20px;
    margin-bottom: 20px;
    .link-title {
      font-size: 14px;
      color: #666666;
      line-height: 36px;
      margin-left: 20px;
    }
  }
}
.footer {
  background: url("../assets/images/footerbg.png");
  height: 200px;
  background-size: 100% 100%;
  .footer-cont {
    width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    .footer-left {
      width: calc(100% - 250px);
      margin-top: 28px;
      p {
        display: inline-block;
        font-size: 11px;
        color: #ffffff;
        margin-top: 20px;
      }
    }
    .footer-right {
      width: 250px;
      .code {
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        color: #ffffff;
      }
      .code-div {
        display: flex;
        margin-top: 26px;
        .code-img {
          width: 50%;
          height: 100px;
          text-align: center;
          img {
            width: 68px;
            height: 68px;
          }
          .title {
            font-size: 12px;
            text-align: center;
            color: #ffffff;
            margin-top: 14px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="container">
    <div class="header">
      <Header></Header>
    </div>
    <div class="nav">
      <Nav :navData="navList" :isActive="nav_id"></Nav>
    </div>
    <div class="content">
      <div v-if="type == 1" class="wCenter">
        <!-- 导航栏 -->
        <div class="navigation">
          <span class="index" @click="jump(1)">首页 > </span>
          <span>会员查询</span>
        </div>
        <!-- 查询框 -->
        <div class="inp">
          <div class="member-find">
            <img src="../assets/images/member_info.png" alt="" />
            <div class="member-title">会员查询</div>
          </div>
          <div class="inp-content">
            <div>
              <span class="star">*</span>
              <span class="inp-title">企业名称 ：</span>
            </div>
            <div class="name">
              <div class="name-input">
                <input
                  type="text"
                  v-model="query.name"
                  placeholder="请输入企业名称查询相关会员信息"
                />
              </div>
            </div>
            <div>
              <span class="star">*</span>
              <span class="inp-title">统一社会信用代码 ：</span>
            </div>
            <div class="name">
              <div class="name-input">
                <input
                  type="text"
                  v-model="query.encoded"
                  placeholder="请输入企业统一社会信用代码"
                />
              </div>
            </div>

            <div class="button">
              <div class="reset" @click="reset()">重置</div>
              <div class="select" @click="sub()">查询</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="type == 2" class="wCenter">
        <!-- 导航栏 -->
        <div class="navigation">
          <span class="index" @click="jump(1)">首页 > </span>
          <span class="index" @click="jump(2)">会员查询 > </span>
          <span class="index">查询结果 </span>
        </div>
        <div class="inp">
          <div class="member-find">
            <img src="../assets/images/member_info.png" alt="" />
            <div class="member-title">查询结果</div>
          </div>
          <div class="inp-content">
            <div class="cname">{{ memberData.cname }}</div>
            <div class="credit">{{ memberData.credit }}</div>
            <div class="inp-info">
              <div class="inp-type">会员类别：</div>
              <div class="inp-info-cont">{{ memberData.uname }}</div>
            </div>
            <div class="inp-info">
              <div class="inp-type">会员级别：</div>
              <div class="inp-info-cont">{{ memberData.grade }}</div>
            </div>
            <div class="inp-info">
              <div class="inp-type">会员编号：</div>
              <div class="inp-info-cont">{{ memberData.encoded }}</div>
            </div>
            <div class="inp-info">
              <div class="inp-type">所属片区：</div>
              <div class="inp-info-cont">{{ memberData.description }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="wCenter">
        <!-- 导航栏 -->
        <div class="navigation">
          <span class="index" @click="jump(1)">首页 > </span>
          <span class="index" @click="jump(2)">会员查询 > </span>
          <span class="index">查询结果 </span>
        </div>
        <div class="inp">
          <div class="member-find">
            <img src="../assets/images/member_info.png" alt="" />
            <div class="member-title">查询结果</div>
          </div>
          <div class="inp-content1">
            <img src="../assets/images/nodata.png" alt="" />
            <div class="nodata">暂无相关内容…</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <div>
      <Foot></Foot>
    </div>
  </div>
</template>

<script>
import { getNav, select_enterprise_vip } from "@/http/api/home/index";
import Header from "../components/header.vue";
import Nav from "../components/nav.vue";
import Foot from "../components/foot.vue";
export default {
  data() {
    return {
      navList: [],
      nav_id: "0",
      active_id: 1,
      title: "协会培训证书查询",
      query: {
        name: "",
        encoded: "",
      },
      type: 1, //1会员查询 2会员查询结果
      memberData: [],
    };
  },
  filters: {},
  components: {
    Header,
    Nav,
    Foot,
  },
  watch: {},
  created() {
    this.getNav();
  },
  methods: {
    getNav() {
      getNav().then((res) => {
        this.navList = res.data.data;
      });
    },
    //重置
    reset() {
      this.query.name = "";
      this.query.encoded = "";
    },
    //查询证书
    sub() {
      //三个输入两个就行
      if (this.query.name == "") {
        alert("请输入企业名称");
        return false;
      }
      if (this.query.encoded == "") {
        alert("统一社会信用代码：");
        return false;
      }
      //调用接口
      select_enterprise_vip(this.query).then((res) => {
        if (res.data.code == 200) {
          this.type = 2;
          this.memberData = res.data.data;
        } else {
          this.type = 3;
          this.query.name = "";
          this.query.encoded = "";
        }
      });
    },
    jump(type) {
      if (type == 1) {
        //首页
        var url = "/index";
        this.$router.push({ path: url });
      } else {
        this.type = 1;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  background: #f7f7f7;
  padding-top: 20px;
  .navigation {
    height: 44px;
    background: #ffffff;
    font-size: 16px;
    color: #666666;
    line-height: 44px;
    padding-left: 11px;
    .index {
      cursor: pointer;
    }
  }
  .find {
    margin-top: 14px;
    height: 58px;
    font-size: 20px;
    color: #333333;
    line-height: 58px;
    border-bottom: 2px solid #f7f7f7;
  }
  .info {
    width: 100%;
    height: auto;
    margin-top: 16px;
    .info-title {
      width: 100%;
      height: 50px;
      background: #dcf4f3;
      font-size: 20px;
      color: #005a69;
      line-height: 50px;
      padding-left: 20px;
    }
    .info-row {
      display: flex;
      border-right: 1px solid #dddddd;
      .info-item {
        width: 25%;
        height: 49px;
        border-left: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        font-size: 16px;
        text-align: center;
        color: #333333;
        line-height: 49px;
      }
      .default {
        color: #005a69;
      }
    }
  }
  .more {
    width: 100%;
    height: 50px;
    margin-top: 30px;
    .certficate {
      height: 50px;
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      text-align: center;
      display: inline-block;
      padding: 13px 28px 12px 29px;
      background: #f7f7f7;
      margin-right: 30px;
      cursor: pointer;
    }
    .certficate-act {
      height: 50px;
      font-size: 18px;
      font-weight: 500;
      color: #005a69;
      text-align: center;
      display: inline-block;
      padding: 13px 28px 12px 29px;
      background: #dcf4f3;
      margin-right: 30px;
      cursor: pointer;
    }
  }
  .certficate-find {
    width: 100%;
    height: 58px;
    line-height: 58px;
    font-size: 20px;
    color: #333333;
    border-bottom: 2px solid #f7f7f7;
    margin-top: 41px;
  }
  .inp {
    width: 100%;
    height: 460px;
    background: #ffffff;
    margin-top: 20px;
    .member-find {
      padding: 20px;
      position: relative;
      .member-title {
        position: absolute;
        top: 25px;
        left: 38px;
        font-weight: 700;
        color: #ffffff;
      }
    }
    .inp-content1 {
      width: 184px;
      height: 130px;
      text-align: center;
      margin: 0px auto;
      margin-top: 110px;
      .nodata {
        font-size: 14px;
        color: #b0b4ba;
      }
    }
    .inp-content {
      width: 750px;
      // margin: 0px auto;
      margin-top: 40px;
      margin-left:450px;
      img {
        width: 184px;
        height: 130px;
      }
      .inp-info {
        display: flex;
        height: 21px;
        margin-top: 32px;

        .inp-type {
          width: 80px;
          height: 21px;
          line-height: 21px;
          font-size: 16px;
          color: #999999;
        }
        .inp-info-cont {
          font-size: 18px;
          color: #333333;
          height: 21px;
          line-height: 21px;
          margin-left: 4px;
        }
      }
      .cname {
        font-size: 20px;
        font-weight: 700;
        color: #059e96;
        height: 20px;
        line-height: 20px;
      }
      .credit {
        font-size: 14px;
        color: #71bdb9;
        height: 19px;
        line-height: 19px;
        margin-top: 10px;
      }
      .star {
        color: #059e96;
      }
      .inp-title {
        font-size: 16px;
        color: #333333;
        height: 21px;
        line-height: 21px;
        margin-top: 44px;
      }
      .tips {
        width: 100%;
        height: 35px;
        background: #f7f7f7;
        font-size: 14px;
        color: #f5a25a;
        line-height: 35px;
        padding-left: 13px;
      }
      .name {
        width: 100%;
        display: flex;
        margin-top: 4px;
        margin-bottom: 20px;
        .name-title {
          width: 113px;
          height: 40px;
          font-size: 18px;
          color: #666666;
          line-height: 40px;
          letter-spacing: 2px;
          text-align: left;
        }
        .name-input {
          width: 320px;
          height: 40px;
          input {
            width: 320px;
            height: 40px;
            border: 1px solid #dddddd;
            padding: 11px;
          }
          input:focus{
            border: 1px solid #059e96;
          }
          input::-webkit-input-placeholder {
            font-size: 14px;
            color: #999999;
          }
        }
      }
      .button {
        width: 100%;
        height: 40px;
        display: flex;
        margin-top: 30px;
        .reset {
          width: 151px;
          height: 40px;
          border: 1px solid #005a69;
          border-radius: 2px;
          font-size: 16px;
          color: #005a69;
          text-align: center;
          line-height: 40px;
          cursor: pointer;
        }
        .select {
          width: 151px;
          height: 40px;
          background: linear-gradient(180deg, #0b918b 6%, #076a81);
          border-radius: 2px;
          font-size: 16px;
          color: #ffffff;
          text-align: center;
          line-height: 40px;
          margin-left: 20px;
          cursor: pointer;
        }
        .select:hover {
          background: linear-gradient(180deg, #25dad4, #008a9b);
        } 
      }
    }
  }
}
</style>
